// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from 'jquery'
global.$ = jQuery
window.$ = window.jQuery = jQuery;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('webpack-jquery-ui/datepicker');

import 'bootstrap';
import "@fortawesome/fontawesome-free/css/all.css";

require("packs/awesomplete")

window.Highcharts = Highcharts;
import Highcharts from 'highcharts';
import addMore from 'highcharts/highcharts-more';
addMore(Highcharts)